<template>
    <div class="add-rise">
        <el-dialog
                :title="isAdd ? '新增收票抬头' : '修改收票抬头'"
                :visible.sync="dialogVisible"
                width="600px"
                @close="handleClose">
            <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="抬头类型" prop="receiveName">
                    <el-radio-group v-model="dataForm.type" size="medium">
                        <el-radio-button label="OWN" >个人</el-radio-button>
                        <el-radio-button label="COMPANY">单位</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label="dataForm.type === 'COMPANY' ? '单位名称' : '发票抬头'" prop="headerName">
                    <el-input v-model="dataForm.headerName" placeholder="请输入发票抬头"></el-input>
                </el-form-item>
                <el-form-item label="发票税号" prop="dutyParagraph" v-if="dataForm.type === 'COMPANY'">
                    <el-input v-model="dataForm.dutyParagraph" placeholder="请输入发票税号"></el-input>
                </el-form-item>
                <el-form-item label="注册地址"  v-if="dataForm.type === 'COMPANY'">
                    <el-input v-model="dataForm.registerAddress" ></el-input>
                </el-form-item>
                <el-form-item label="注册电话"  v-if="dataForm.type === 'COMPANY'">
                    <el-input v-model="dataForm.registerPhone"></el-input>
                </el-form-item>
                <el-form-item label="开户银行"  v-if="dataForm.type === 'COMPANY'">
                    <el-input v-model="dataForm.bankName"></el-input>
                </el-form-item>
                <el-form-item label="银行账号"  v-if="dataForm.type === 'COMPANY'">
                    <el-input v-model="dataForm.bankNo"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
             <el-button @click="handleClose">取 消</el-button>
             <el-button type="primary" @click="onsubmit" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'add-address',
        data () {
            return {
                dialogVisible: false,
                dataForm: {
                    type: 'COMPANY',
                    headerName: '',
                    dutyParagraph: '',
                    registerAddress: '',
                    registerPhone: '',
                    bankName: '',
                    bankNo: ''
                },
                rules: {
                    headerName: [
                        { required: true, message: '请输入发票抬头', trigger: 'blur' }
                    ],
                    dutyParagraph: [
                        { required: true, message: '请输入纳税号', trigger: 'blur' }
                    ]
                },
                isAdd: true,
                loading: false
            }
        },
        computed: {
            userId: {
                get () {
                    return this.$store.state.user.id
                }
            }
        },
        methods: {
            //   初始化
            init (item) {
                this.dialogVisible = true
                if (item && item.id) {
                    this.isAdd = false
                    this.dataForm = this.$deepClone(item)
                }
            },
            // 提交
            onsubmit () {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let data = this.$deepClone(this.dataForm)
                        data.userId = this.userId || ''
                        this.$http({
                            url: this.$http.adornUrl(this.isAdd? this.$api.INVOICE.RISE_SAVE : this.$api.INVOICE.RISE_UPDATE),
                            method: 'POST',
                            data: this.$http.adornData({
                                ...data
                            })
                        }).then(({data}) => {
                            if (data && data.code === 0) {
                                this.loading = false
                                this.$message.success('保存成功')
                                this.dialogVisible = false
                                this.$emit('closeCallBack', true)
                            } else {
                                this.loading = false
                            }
                        })
                    }
                })
            },
            //   关闭
            handleClose() {
                this.dialogVisible = false
                this.$emit('closeCallBack')
            }
        }
    }
</script>

<style scoped>

</style>