<template>
    <div class="invoice-rise uf uf-column">
        <div>
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="getRiseList(true)">
                <el-form-item label="抬头类型">
                    <el-select v-model="dataForm.type" placeholder="请选择" @change="getRiseList(true)">
                        <el-option
                                v-for="item in dict['INVOICE_HEADER_TYPE']"
                                :key="item.dictId"
                                :label="item.dictName"
                                :value="item.dictId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getRiseList(true)">查询</el-button>
                    <el-button type="primary" @click="addRise">新增</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="uf-f1" style="overflow:auto;">
            <el-table
                    :data="dataList"
                    border
                    ref="tableRef"
                    height="100%"
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column type="index" label="序号" header-align="center" align="center" width="80" :index="indexMethod"></el-table-column>
                <el-table-column label="发票抬头" prop="headerName" header-align="center" align="center"></el-table-column>
                <el-table-column label="抬头类型"  header-align="center" align="center">
                    <template slot-scope="scope">
                        <span>{{riseTypeDict[scope.row.type]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="发票税号" prop="dutyParagraph" header-align="center" align="center" v-if="dataForm.type === 'COMPANY'"></el-table-column>
                <el-table-column label="注册地址" prop="registerAddress" header-align="center" align="center" v-if="dataForm.type === 'COMPANY'"></el-table-column>
                <el-table-column label="注册电话" prop="registerPhone" header-align="center" align="center" v-if="dataForm.type === 'COMPANY'"></el-table-column>
                <el-table-column label="开户银行" prop="bankName" header-align="center" align="center" v-if="dataForm.type === 'COMPANY'"></el-table-column>
                <el-table-column label="银行账号" prop="bankNo" header-align="center" align="center" v-if="dataForm.type === 'COMPANY'"></el-table-column>
                <el-table-column label="操作" prop="address" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="addRise(scope.row)">修改</el-button>
                        <el-button type="text" size="small" @click="deleteRise(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
<!--        <div class="uf uf-ac uf-pe mt-2">-->
<!--            <el-pagination-->
<!--                    @size-change="sizeChangeHandle"-->
<!--                    @current-change="currentChangeHandle"-->
<!--                    :current-page="pageIndex"-->
<!--                    :page-sizes="[10, 20, 50, 100]"-->
<!--                    :page-size="pageSize"-->
<!--                    :total="totalCount"-->
<!--                    layout="total, sizes, prev, pager, next, jumper">-->
<!--            </el-pagination>-->
<!--        </div>-->
        <!--        新增收票地址-->
        <addRise v-if="addRiseVisible" ref="addRiseRef" @closeCallBack="closeCallBack"></addRise>
    </div>
</template>

<script>
    import addRise from './components/add-rise'
    export default {
        name: 'invoice-address',
        components: {
            addRise
        },
        data () {
            return {
                dataForm: {
                    type: 'COMPANY'
                },
                dataList: [],
                dataListLoading: false,
                //    分页参数
                pageIndex: 1,
                pageSize: 10,
                totalCount: 0,
                //    新增收票地址弹框显隐
                addRiseVisible: false,
                dict: {},
                riseTypeDict: {}
            }
        },
        created () {
            this.$nextTick(() => {
                this.$getDictList('INVOICE_HEADER_TYPE', dict => {
                    this.dict = dict || {}
                })
                this.$getDictMap('INVOICE_HEADER_TYPE', dict => {
                    this.riseTypeDict = dict['INVOICE_HEADER_TYPE']
                })
                this.getRiseList()
            })
        },
        methods: {
            //   获取收票地址列表
            getRiseList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
               this.$http({
                   url: this.$http.adornUrl(this.$api.INVOICE.RISE_LIST),
                   method: 'GET',
                   params: this.$http.adornParams({
                       type: this.dataForm.type
                   })
               }).then(({data}) => {
                   if (data && data.code === 0) {
                       this.dataList = data.list || []
                       if (isPage) {
                           this.$nextTick(() => {
                               this.$refs.tableRef.doLayout()
                           })
                       }
                   }
               })
            },
            // 添加收票地址
            addRise (item = {}) {
                this.addRiseVisible = true
                this.$nextTick(() => {
                    this.$refs.addRiseRef.init(item)
                })
            },
            // 新增 弹框关闭回调
            closeCallBack (isRefresh = false) {
                this.addRiseVisible = false
                if (isRefresh) {
                    this.getRiseList(true)
                }
            },
            // 删除 收票抬头
            deleteRise (item) {
                this.$confirm('确定要删除该收票抬头吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$http.adornUrl(this.$api.INVOICE.RISE_DELETE),
                        method: 'POST',
                        data: this.$http.adornData([item.id], false)
                    }).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            })
                            this.getRiseList(true)
                        }
                    })
                }).catch(() => {
                })
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getRiseList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getRiseList()
            },
            indexMethod (index) {
                return (this.pageIndex - 1) * this.pageSize + index + 1
            }
        }
    }
</script>

<style scoped>
    .invoice-rise {
        height: calc(100vh - 220px);
    }

</style>